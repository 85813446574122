<template>
	<div class="home">
		<hero image="home.jpg" class="mb-5">
			<h1 class="mb-4 mb-lg-5">Online learning to help you
				<span class="moving-underline" v-in-viewport>
					launch or advance your career
				</span>
			</h1>
			<h5 class="tiny font-weight-bold cursor-pointer" @click="$router.push('/international-trade')">Industry courses and accreditations</h5>
			<div class="has-moving-arrow">
				<router-link :to="$store.getters.courseLink(1)" class="text-white moving-arrow visible-arrow d-block font-size-xs mb-2 mb-lg-1">Safe Container Loading Practices</router-link>
			</div>
			<div class="has-moving-arrow">
				<router-link :to="$store.getters.courseLink(15)" class="text-white moving-arrow visible-arrow d-block font-size-xs mb-2 mb-lg-1">Approved Arrangements Accreditation</router-link>
			</div>
			<div class="has-moving-arrow">
				<router-link to="/international-trade" class="mr-3 mb-3 text-white moving-arrow visible-arrow d-block btn-xs">VIEW ALL COURSES</router-link>
			</div>
			<h5 class="tiny font-weight-bold cursor-pointer" @click="$router.push('/compliance')">Corporate compliance courses</h5>
			<div class="has-moving-arrow">
				<router-link to="/compliance" class="mr-3 mb-3 text-white moving-arrow visible-arrow d-block btn-xs">VIEW ALL COURSES</router-link>
			</div>
			<h5 class="tiny font-weight-bold cursor-pointer" @click="$router.push('/training')">Nationally recognised training</h5>
			<div class="has-moving-arrow">
				<router-link :to="$store.getters.courseLink(3)" class="text-white moving-arrow visible-arrow d-block font-size-xs mb-2 mb-lg-1">TLIX0008 Comply with biosecurity border clearance</router-link>
			</div>
			<div class="has-moving-arrow">
				<router-link to="/training" class="mr-3 mb-3 text-white moving-arrow visible-arrow d-block btn-xs">VIEW ALL COURSES</router-link>
			</div>
		</hero>
		<featured-courses class="mb-5"/>
		<b-container>
			<b-row class="stretch-left justify-content-center mb-md-7 mb-6">
				<b-col md="6" lg="5" class="d-flex align-items-center">
					<div class="py-3 py-lg-0 ">
						<h3 class="text-grey">Need to Train Your Team?</h3>
						<h5 class="text-grey font-weight-normal pb-3 pr-3 pr-lg-0">We offer flexible, cost-effective group memberships for your business, school, or government organisation. Contact us to learn more</h5>
						<b-button @click="$router.push('/enterprise')">Start Here</b-button>
					</div>
				</b-col>
				<b-col md="6" lg="5">
					<div style="background-image:url(/assets/img/trainYourTeam.jpg)" class="train-image"></div>
					<!-- <img src="/assets/img/trainYourTeam.jpg" alt="" class="w-100"> -->
				</b-col>
			</b-row>
			<b-row class="justify-content-center mb-6">
				<b-col lg="5" md="10"><img src="/assets/img/logigrl.jpg" alt=""></b-col>
				<b-col lg="5" md="10" class="pt-3 pt-lg-0">
					<h2>The global supply chain sector offers <span class="moving-underline" v-in-viewport>countless opportunities</span> for professionals seeking an exciting and fulfilling career. Be part of something big.</h2>
					<b-button class="px-4 mt-2" @click="$router.push('career-path')">Let’s go</b-button>
				</b-col>
			</b-row>
			<b-row class="justify-content-center pb-4">
				<home-icon-widget :item="iconWidgets[0]" />
				<home-icon-widget :item="iconWidgets[1]" />
			</b-row>
			<b-row class="justify-content-center pb-6">
				<home-icon-widget :item="iconWidgets[2]" />
				<home-icon-widget :item="iconWidgets[3]" />
			</b-row>
			<b-row>
				<b-col>
					<heading sub="See how industry experts got there with WiseTech Academy">Our Stories</heading>
				</b-col>
			</b-row>
		</b-container>
		<video-carousel class="mb-5 mb-sm-6"/>
		<contact-form title="Like to Keep Informed?">
			For more information, please explore our course catalogue. If you'd like to be informed of new course releases, please leave us your contact information.
		</contact-form>
	</div>
</template>

<script>
import { HomeIconWidget } from '@/components/ui'

export default {
	name: 'Home',
	components: {
		'home-icon-widget': HomeIconWidget,
	},
	computed: {
		iconWidgets() {
			return [
				{img: 'icon-value.svg', title: 'Choice', body: 'Choose from a range of internationally recognized courses to suit your interests, job requirements or career stage.'},
				{img: 'icon-convenience.svg', title: 'Convenience', body: 'All our courses can be completed online, which means you have the flexibility to study at a time and place that suits you.'},
				{img: 'icon-quality.svg', title: 'Quality', body: 'Our high quality courses are prepared and delivered by some of the most experienced leaders in the industry.'},
				{img: 'icon-communication.svg', title: 'Support', body: 'Our online learning provides the support you need to advance your career.'},
			]
		},
	},
	metaInfo: {
		title: 'Homepage',
	},
	created() {
		if (this.$route.query.logon) {
			this.$store.commit('login/login')
		}
	}
}
</script>

<style lang="scss">

.stretch-left{
	position: relative;
	&::before{
		position: absolute;
		content: ' ';
		background-color: $light;
		right: calc(50% - 30px);
		top: 0;
		bottom: 0;
		width: calc(50vw + 30px);
	}
	@include media-breakpoint-down(sm){
		> div:first-child {
			order: 1;
		}
		&::before{
			right: $grid-gutter-width / 2;
			width: 100vw;
		}
	}
}
.train-image{
	height: 34vw;
	max-height: 280px;
	min-height: 150px;
}
</style>